import './App.css';
// import { LandingPage  } from './components/LandingPage';
import MobileAppShowcase  from './MobileAppShowcase';
import Promo from './Promo';
import { createBrowserRouter, RouterProvider } from "react-router-dom";


function App() {
  const router = createBrowserRouter([
    {path: "/", element: <MobileAppShowcase />},
    {path: "p", children:[
      {path: ":code", element: <Promo />}
    ]}
  ]);
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
