import React from 'react';
import { BalapanIconWhite, AppStoreIcon, GooglePlayIcon } from './Icons';

const MobileAppShowcase = () => {
  return (
    <div className="bg-[#5fa9dd] text-white min-h-screen flex items-center">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col items-center">
          <BalapanIconWhite className="w-auto h-24 mb-4" />
          {/* <BalapanCirclesWhite className="w-auto h-12 mb-8" /> */}
          
          <h2 className="text-3xl font-semibold text-center mb-6">
            Скачайте наше приложение
          </h2>
          <p className="text-xl text-center max-w-3xl mb-12">
          и открывайте мир бонусов, онлайн-заказов и эксклюзивных акций!
            </p>

          <div className="flex flex-wrap justify-center gap-6">
            <a href="https://apps.apple.com/app/%D0%B1%D0%B0%D0%BB%D0%B0%D0%BF%D0%B0%D0%BD/id6444753517" className="flex items-center px-6 py-3 rounded-lg bg-white text-[#5fa9dd] hover:bg-[#5fa9dd] hover:text-white hover:border hover:border-white transition-colors duration-300">
              <AppStoreIcon className="w-8 h-8 mr-3" />
              <div>
                <div className="text-xs">Download on the</div>
                <div className="text-xl font-semibold">App Store</div>
              </div>
            </a>
            <a href="https://play.google.com/store/apps/details?id=app.abdullah.balapan&hl=en-US" className="flex items-center px-6 py-3 rounded-lg bg-white text-[#5fa9dd] hover:bg-[#389cfa] hover:text-white hover:border hover:border-white transition-colors duration-300">
              <GooglePlayIcon className="w-8 h-8 mr-3" />
              <div>
                <div className="text-xs">Get it on</div>
                <div className="text-xl font-semibold">Google Play</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppShowcase;